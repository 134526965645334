import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { login, logout } from '@store/reducers/userReducer';
import { postLogin, putLogout } from '@api';
import { Space, Modal, Input, Form, Button, Toast } from 'antd-mobile';
import { Dropdown } from 'antd';
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { NavLink, useNavigate } from 'react-router-dom';
import './index.less';
import { normalRequestErrorHandler } from '@util';

const logo = 'https://pmk.oss-cn-shenzhen.aliyuncs.com/pmk_web/image/logo.png';
export default function Navigation() {
  const user = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();
  const [scrollY, setScrollY] = useState(0);

  const scrollFunc = (e) => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    // 这个代码相当于 componentDidMount
    window.addEventListener('scroll', scrollFunc);
    // 可选：返回一个清理函数，相当于 componentWillUnmount
    return () => {
      window.removeEventListener('scroll', scrollFunc);
    };
  }, []); 
  

  // 用户登录表单
  let loginBox = null;
  const UserLoginForm = () => {
    const [visible, setVisible] = useState(false);
    const onFinish = (values) => {
      postLogin(values)
        .then(res => {
          dispatch(login(res));
          loginBox.close();
          Toast.show({
            icon: 'success',
            content: '登录成功'
          });
        })
        .catch(err => {
          Toast.show({
            icon: 'error',
            content: err.msg
          });
          normalRequestErrorHandler(err);
        });
    };
    return (
      <>
        <h2 style={{ width: "100%", textAlign: 'center', padding: '0 0 20px 0', margin: '0' }}>登录</h2>
        <Form
          
          layout='vertical'
          onFinish={onFinish}
          footer={
            <Button block type='submit' color='primary' size='large'>
              提交
            </Button>
          }
        >
          <Form.Item label='账号' name='account' rules={[{ required: true, message: '账号不能为空' }]} initialValue="demo">
            <Input style={{ width: "250px" }} placeholder='请输入账号' clearable defaultValue="demo"/>
          </Form.Item>
          <Form.Item
            initialValue="demo"
            label='密码'
            name='password'
            rules={[{ required: true, message: '密码不能为空' }]}
            extra={
              <div className="eye">
                {!visible ? (
                  <EyeInvisibleOutline onClick={() => setVisible(true)} />
                ) : (
                  <EyeOutline onClick={() => setVisible(false)} />
                )}
              </div>
            }
          >
            <Input
              style={{ width: "250px" }}
              placeholder='请输入密码'
              clearable
              defaultValue="demo"
              type={visible ? 'text' : 'password'}
            />
          </Form.Item>
        </Form>
      </>
    )
  }
  // 显示登录窗口
  const showLogin = () => {
    loginBox = Modal.show({
      bodyStyle: {
        maxHeight: '150vh'
      },
      content: (<UserLoginForm  />),
      closeOnMaskClick: true,
      confirmText: '登录'
    });
  };

  // 退出登录
  const logoutClick = () => {
    putLogout()
      .finally(() => dispatch(logout()))
      .catch(normalRequestErrorHandler);
  };

  // 点击菜单
  const navlink = useNavigate();
  const clickMenu = (e) => {
    navlink(`/pages/article/${e.key}`, {
      state: { title: e.domEvent.target.textContent, key: e.key }
    });
  };

  return (
    <div className='navigation'>
      { scrollY > 100 ? <div className='n-box' /> : '' }
      <div className={scrollY > 100 ? 'n-box fixed' : 'n-box'}>
        <div className='content'>
          <div className='left'>
            <Space className='space'>
              <NavLink className='logo' to="home">
                <img src={logo} alt="logo"/>
              </NavLink>
              <Dropdown menu={{
                items: [
                  {key: 'css', label: 'CSS'},
                  {key: 'javascript', label: 'JAVASCRIPT'},
                  {key: 'framework', label: '框架'},
                  {key: 'tools', label: '工具'},
                  {key: 'other', label: '其他'}
                ],
                onClick: clickMenu
              }} placement="bottom" arrow={{ pointAtCenter: true }}>
                <span className='menu-item'>前端</span>
              </Dropdown>
              <Dropdown menu={{
                items: [
                  {key: 'nodejs', label: 'NODEJS'},
                  {key: 'mysql', label: 'MYSQL'},
                  {key: 'server', label: '服务器'}
                ],
                onClick: clickMenu
              }} placement="bottom" arrow={{ pointAtCenter: true }}>
                <span className='menu-item'>后端</span>
              </Dropdown>
              <Dropdown menu={{
                items: [
                  {key: 'app', label: 'APP'},
                  {key: 'mini-program', label: '小程序'}
                ],
                onClick: clickMenu
              }} placement="bottom" arrow={{ pointAtCenter: true }}>
                <span className='menu-item'>移动端</span>
              </Dropdown>
            </Space>
          </div>
          {/* <div className='right'>
            <Space className='space'>
              {
                user.isLoggedIn && user.token ?
                <Dropdown menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <NavLink to="/admin/article_management" target="_blank">
                          <span>前往后台</span>
                        </NavLink>
                      ),
                    },
                    { key: '2', label: <span onClick={logoutClick}>退出登录</span> }
                  ]
                }} placement="bottomRight" arrow={{ pointAtCenter: true }}>
                  <div className='avatar'>
                    <img src={user.avatar} alt={user.avatar}/>
                    <span className='name'>{user.user_name}</span>
                  </div>
                </Dropdown>
                : <span className='menu-item login' onClick={showLogin}>登录</span>
              }
            </Space>
          </div> */}
        </div>
      </div>
    </div>
  )
}
