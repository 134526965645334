import React from 'react'
import { Outlet } from 'react-router-dom';
import { Footer as AntdMFooter } from 'antd-mobile';
import Navigation from '@components/Navigation';
import './index.less';

export default function Pages() {
  let dom = (
    <div className='footer-bottom'>
      <div className='item'>
        @ 2020 - {new Date().getFullYear().toString()}
      </div>
      <div className='item'>
        <img src="https://pmk.oss-cn-shenzhen.aliyuncs.com/pmk_web/image/benan_icon.png" alt="" />
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44172302000127" rel="noreferrer" target="_blank">粤公网安备44172302000127</a>
      </div>
      <div className='item'>
        <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank">粤ICP备20001347号-1</a>
      </div>
    </div>
  );
  return (
    <>
      <Navigation/>
      <Outlet/>
      <AntdMFooter 
          className='footer'
          label='没有更多了'
          content={dom}
      />
      
    </>
  )
}
